import { React, useMemo } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDpwjDNq8UKGlzyy4kI5yaXBMaAkf1UXtA",
  });
  const center = useMemo(() => ({ lat: 54.1265568576387, lng: 13.52142163788011 }), []);
return(
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Kontakt" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>So finden Sie uns</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">Am Strandweg 1, 17509 Loissin</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Öffnungszeiten</p>
        {/* <p className="p__opensans">Mo, Di, Do & Fr: 11:30 - 20:00</p> */}
        <p className="p__opensans">Fr - So: 11:30 - 20:00</p>
        <p className="p__opensans">Unter der Woche können wir für Gruppen ab 10 Personen öffnen.</p>
      </div>
      {/* <button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Visit Us</button> */}
    </div>

    <div className="app__wrapper_google_map">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
        >
          <Marker position={center} icon={images.marker} />
        </GoogleMap>
      )}
    </div>
  </div>
);
};

export default FindUs;
