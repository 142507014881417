import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';

import images from '../../constants/images';

import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className='app__navbar'>
      <div className="app__navbar-logo">
        {/* <img src={images.gericht} alt="app logo" /> */}
        <h1 className=' app__navbar-title'>Boddenrestaurant Loissin</h1>
      </div>
      <ul className='app__navbar-links'>
        <li className='p__opensans'><Link to="/">Home</Link> </li>
        <li className='p__opensans'><Link to="/about">Über uns</Link> </li>
        <li className='p__opensans'><Link to="/karte">Speisekarte</Link> </li>
        <li className='p__opensans'><Link to="/contact">So finden Sie uns</Link> </li>

      </ul>
      <div className='app__navbar-login'>
        {/* <a href="#login" className='p__opensans'>Kontakt</a> */}
        <Link to="/book" className='p__opensans'>Reservieren</Link>

        {/* <div />
      
      <a href="/"className='p__opensans'>‎Login In / Registration   ‎‎</a> */}

      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color='#fff' fontSize={27} onClick={() => setToggleMenu(true)} />

        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay_close" onClick={() => setToggleMenu(false)} />

            <ul className="app__navbar-smallscreen_links">
              <li><Link to="/" onClick={() => setToggleMenu(false)}>Home</Link> </li>
              <li><Link to="/about" onClick={() => setToggleMenu(false)}>Über uns</Link> </li>
              <li><Link to="/karte" onClick={() => setToggleMenu(false)}>Speisekarte</Link> </li>
              <li><Link to="/contact" onClick={() => setToggleMenu(false)}>So finden Sie uns</Link> </li>
              {/* <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li> */}
              {/* <li><a href="#about" onClick={() => setToggleMenu(false)}>Über uns</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>Speisekarte</a></li>
              <li><a href="#awards" onClick={() => setToggleMenu(false)}>So finden Sie uns</a></li> */}
              {/* <li><a href="#contact" onClick={() => setToggleMenu(false)}>Contact</a></li> */}
            </ul>

          </div>
        )}

      </div>
    </nav>
  );
};

export default Navbar;
