import React from 'react';
import { SubHeading } from '../../components';
import './Header.css';

const Header = (props) => {
  return (<div>
    <div className="app__header section__padding" id="home">
      <div className="app__wrapper app__wrapper_info">
        {props.title ? "" : <SubHeading title="Herzlich Willkommen im" /> }
        <h1 className="app__header-h1">{props.title ? props.title : 'Boddenrestaurant Loissin'}</h1>
        <p className="p__opensans" style={{ margin: '2rem 0', textAlign: 'center'}}>{props.description ? props.description : "Wir freuen uns, dass wir Sie in unserem Restaurant begrüßen und verwöhnen dürfen. Bei schöner Lage nah am Wasser, können Sie Ihre Sorgen vom Alltagsstress einfach mal vergessen."}</p>
        {/* {props.title ? null : <button type="button" className="custom__button">Zur Karte</button>} */}
      </div>
      {/* <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div> */}
    </div>
  </div>);
};

export default Header;
