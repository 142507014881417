import React from "react";
import './TopImage.css'
import { images } from "../../constants";

const TopImage = () => {
    return (
        <div>
            <div className='top_images'>
                <img className="app_background_image" src={images.image15} alt="header_img" />
                <img className="boddenrestaurant_img" src={images.boddenrestaurant} alt="header_img" />
            </div>
        </div>
    )
}

export default TopImage;