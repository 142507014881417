import React from 'react';

import SubHeading from '../SubHeading/SubHeading';
import './CallToAction.css';

const CallToAction = () => (
  <div className="app__newsletter" id="book">
    <div className="app__newsletter-heading">
      <SubHeading title="Sie möchten reservieren oder bestellen?" />
      <h4 className="headtext__cormorant">015228389717</h4>
      <p className="p__opensans">Wir freuen uns auf Ihren Anruf!</p>
    </div>
    {/* <div className="app__CallToAction-input flex__center">
      <input type="text" placeholder="What's your good name?"/>
      <input type="email" placeholder="Enter your email address" />
      <input type="text" placeholder='What would you like to bring to our attention?'/>
      <input type="text" placeholder='Share details about'/>
      <button type="button" className="custom__button">Transmit message</button>
    </div> */}
  </div>
);

export default CallToAction;
