import React from 'react';
import { Link } from 'react-router-dom';
import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Klassische Deutsche Küche" />
      <h1 className="headtext__cormorant">Unsere Karte</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Topf und Pfanne</p>
        <div className="app__specialMenu_menu_items">
          {data.menu['topf_und_pfanne'].slice(0, 5).map((alokoholfreie_getraenke, index) => (
            <MenuItem key={alokoholfreie_getraenke.title + index} title={alokoholfreie_getraenke.title} price={alokoholfreie_getraenke.price} tags={alokoholfreie_getraenke.description} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.image25} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Fischgerichte</p>
        <div className="app__specialMenu_menu_items">
          {data.menu['fischgerichte'].map((alkoholische_getraenke, index) => (
            <MenuItem key={alkoholische_getraenke.title + index} title={alkoholische_getraenke.title} price={alkoholische_getraenke.price} tags={alkoholische_getraenke.description} />
          ))}
        </div>
      </div>
    </div>

    <div style={{ marginTop: 15 }}>
      <Link to="/karte">
        <button type="button" className="custom__button">Zur Karte</button>
      </Link>
    </div>
  </div>
);

export default SpecialMenu;
