import './SpecialMenu.css'
import { MenuItem } from '../../components';
import { images } from '../../constants';

const GeneralMenu = (props) => {
    let image = null;
    let backgroundcolor = "";
    function formatObjectName(name) {
        let words = name.replace(/_/g, ' ').split(' ');
        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return words.join(' ');
    }

    const halfLength = Math.ceil(props.items.length / 2);
    let firstHalf = [];
    let secondHalf = [];
    let firstTitle = "";
    let secondTitle = "";
    console.log(secondHalf)

    if (props.singleOrDouble === "single") {
        firstTitle = props.title;
        firstHalf = props.items.slice(0, halfLength);
        secondHalf = props.items.slice(halfLength);
    } else {
        let keys = Object.keys(props.items);
        firstTitle = keys[0];
        secondTitle = keys[1];
        firstTitle = formatObjectName(firstTitle);
        if (keys[1]) {
            secondTitle = formatObjectName(keys[1]);
        }
        firstHalf = props.items[keys[0]];
        secondHalf = props.items[keys[1]];
    }


    switch (true) {
        case firstTitle.includes("Alkoholfrei"):
            image = images.coke2;
            break;
        case firstTitle.includes("Alkohol"):
            image = images.image59;
            break;
        case firstTitle.includes("Topf"):
            image = images.image14;
            break;
        case firstTitle.includes("Fisch"):
            image = images.image5;
            break;
        case firstTitle.includes("Saisonal"):
            image = images.soup;
            break;
        case firstTitle.includes("Spezial"):
            image = images.image24;
            break;
        case firstTitle.includes("Vorspeis"):
            image = images.salad;
            break;
        case firstTitle.includes("Salat"):
            image = images.salad;
            break;
        case firstTitle.includes("Dessert"):
            image = images.image13;
            break;
        case firstTitle.includes("Kinder"):
            image = images.image49;
            break;
        default:
            image = images.steak4;
            break;
    }

    if (props.sectionNumber % 2 === 0) {
        backgroundcolor = null;
    } else {
        backgroundcolor = "var(--color-black)";
    }

    let imageComponent = <div className="app__specialMenu-menu_img"> <img src={image} alt="menu__img" /></div>

    return (
        <div style={{ background: backgroundcolor }} className='app__bg app__specialMenu flex__center section__padding'>
            {/* <p className="app__specialMenu-menu_heading">{props.title}</p> */}

            <div className="app__specialMenu-menu">
                {props.sectionNumber % 2 === 0 ? imageComponent : null}
                <div className="app__specialMenu-menu_wine  flex__center">
                    <p className="app__specialMenu-menu_heading">{firstTitle}</p>
                    <div className="app__specialMenu_menu_items">
                        {firstHalf.map((item, index) => (
                            <MenuItem key={item.title + index} title={item.title} price={item.price} tags={item.description} />
                        ))}
                    </div>
                </div>
                <div className="app__specialMenu-menu_cocktails  flex__center">
                    <p className="app__specialMenu-menu_heading">{props.singleOrDouble === "double" ? secondTitle : ""}</p>
                    {props.singleOrDouble === "single" ? <p className="app__specialMenu-menu_heading" style={{ color: backgroundcolor, opacity: 0 }}>{props.singleOrDouble === "single" ? firstTitle : ""}</p> : null}
                    <div className="app__specialMenu_menu_items">
                        {typeof secondHalf !== "undefined" && secondHalf.map((item, index) => (
                            <MenuItem key={item.title + index} title={item.title} price={item.price} tags={item.description} />
                        ))}
                    </div>
                </div>
                {props.sectionNumber % 2 !== 0 ? imageComponent : null}
            </div>
        </div>
    )
}

export default GeneralMenu;