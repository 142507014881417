import React from 'react';
import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu, News } from './container';
import { Navbar } from './components';
import { AboveNavbar } from './components';
import './Home.css';
import TopImage from './container/TopImage/TopImage';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { images } from './constants';

const Home = () => {
  window.scrollTo(0, 0);
  useEffect(() => {
    let pathname = window.location.pathname;
    const isHomepage = pathname === "/";

    const isAboutUs = pathname === "/about";
    const aboutSection = document.getElementById("about");

    const isContact = pathname === "/contact";
    const contactSection = document.getElementById('contact')

    const isBook = pathname === "/book";
    const bookingSection = document.getElementById('book');

    if (isAboutUs) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
    else if (isHomepage) {
      
    } else if (isContact) {
      contactSection.scrollIntoView({behavior: 'smooth'})
    } else if (isBook) {
      bookingSection.scrollIntoView({behavior: 'smooth'})
    }
  });

  return (
    <div>
      <Navbar />
      <TopImage />
      <Header />
      <News/>
      <SpecialMenu />
      <AboutUs />
      <FindUs />
      <Footer />
    </div>
  );
};

export default Home;
