import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="">
    <FooterOverlay />
    <Newsletter />
    

    <div className="app__footer-links">
      {/* <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Model Town,Rohtak</p>
        <p className="p__opensans">+91 9992878491</p>
       
      </div> */}

      <div className="app__footer-links_logo">
        {/* <img src={images.gericht} alt="footer_logo" /> */}
        <p className="p__opensans">Unser Social Media:</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href='https://www.facebook.com/profile.php?id=61556802506926' target='_blank' rel='noopener noreferrer'>
            <FiFacebook />
          </a>
          <a href='https://www.instagram.com/boddenrestaurant_loissin?igsh=cmYzbDg3M2s0eDZo' target='_blank' rel='noopener noreferrer'>
          <FiInstagram />
          </a>
        </div>
      </div>
      
      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Öffnungszeiten</h1>
        {/* <p className="p__opensans">Mo, Di, Do und Fr:</p>
        <p className="p__opensans">11:30 - 20:00 Uhr</p> */}
        <p className="p__opensans">Freitags, Samstags und Sonntags:</p>
        <p className="p__opensans">11:30 - 20:00 Uhr</p>
        <p className="p__opensans">Unter der Woche können wir für Gruppen ab 10 Personen öffnen.</p>

      </div>
      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Impressum</h1>
        <p className="p__opensans">Boddenrestaurant Loissin</p>
        <p className="p__opensans">Inhaberin Ilka Schlewitt</p>
        <p className="p__opensans">Am Strandweg 1</p>
        <p className="p__opensans">17509 Loissin</p>
        <p className="p__opensans">Telefon: 015228389717</p>
        <p className="p__opensans">E-Mail:
          <a href="mailto:
          boddenrestaurant-loissin@web.de"> boddenrestaurant-loissin@web.de</a>
        </p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 Campertreff Boddenrestaurant Loissin</p>
    </div>
  </div>
);

export default Footer;
