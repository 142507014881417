import images from './images';

const menu = {
alkoholfreie_getränke: [
  {
    title: 'Mineralwasser',
    price: '2,50€, 4,20€, 5,90€',
    description: 'still oder feinperlig | 0,2l, 0,4l, 0,7l',
  },
  {
    title: 'Coca-Cola, Cola Zero, Fanta, Sprite, Spezi',
    price: '2,80€, 4,50€',
    description: '0,2l, 0,4l',
  },
  {
    title: 'Rote Fassbrause',
    price: '2,80€, 4,50€',
    description: '0,2l, 0,4l',
  },
  {
    title: 'Schweppes',
    price: '3,20€, 5,50€',
    description: 'Bitter Lemon, Tonic Water, Ginger Ale | 0,2l, 0,4l',
  },
  {
    title: 'Säfte',
    price: '3,00€, 5,30€',
    description: 'Orange, Apfel (klar), Kirsche, Banane, Tomate | 0,2l, 0,4l',
  },
  {
    title: 'Saftschorle',
    price: '2,80€, 4,50€',
    description: '0,2l, 0,4l',
  },
], alkoholische_getränke: [
  {
    title: 'Martini',
    price: '5,50€',
    description: '5 cl',
  },
  {
    title: "Campari",
    price: '5,50€',
    description: '5 cl',
  },
  {
    title: 'Proseco',
    price: '3,10€',
    description: '0,1l',
  },
  {
    title: 'Proseco mit Lavendel',
    price: '4,50€',
    description: '0,1l',
  },
  {
    title: 'Sekt (trocken, halbtrocken, mild)',
    price: '3,10€',
    description: '0,1l',
  },
  {
    title: 'Hugo',
    price: '7,50€',
    description: '',
  },
  {
    title: 'Aperol',
    price: '7,50€',
    description: '',
  },
], vorspeisen: [
  {
    title: 'Loissiner Fischsuppe',
    price: '6,50€',
    description: 'tomatisierte Fischsuppe mit Wurzelgemüse & ausgesuchten Fischfilets',
  },
  // {
  //   title: 'Kesselgulasch',
  //   price: '6,50€',
  //   description: 'pikanter Kesselgulasch vom Schwein',
  // },
], salate: [
  {
    title: 'Wildkräutersalat',
    price: '5,90€',
    description: 'mit hauseigenem Dressing & Baguette wahlweise mit: gebratenen Hähnchenbruststreifen (9,90), Garnelen (6 Stück) oder Räucherlachsstreifen (11,90)',
  }
], kleine_gerichte: [
  // {
  //   title: 'Ragout Fin',
  //   price: '6,50€',
  //   description: 'von der Pute und vom Kalb gratiniert mit frischem Baguette',
  // },
  {
    title: 'Currywurst',
    price: '8,90€',
    description: 'mit hausgemachter Currysauce und Pommes frites',
  },
], topf_und_pfanne: [
  {
    title: 'Hamburger Schnitzel (vom Schwein)',
    price: '14,90€',
    description: 'mit Pommes oder Setzei'
  },
  {
    title: 'Schnitzel vom Schwein',
    price: '15,50€',
    description: 'mit Rahmchampignons & Herzoginkartoffeln'
  },
  // {
  //   title: 'Schweinesteak',
  //   price: '14,50€, 16,50€',
  //   description: 'mit Käuterbutter, Buttererbsen & Pommes oder au four mit Pommes'
  // },
  {
    title: 'Hähnchen „Italienische Art“',
    price: '16,80€',
    description: 'mit Basilikum-Pesto-Spaghetti & Tomatenconcassé'
  },
  // {
  //   title: 'Geschnetzeltes vom Schweinerücken',
  //   price: '14,50€',
  //   description: 'mit frischen Champignons und Herzoginkartoffeln'
  // },
  {
    title: 'Rumpsteak',
    price: '26,50€',
    description: 'mit gebratenen Champignons, Kräuterbutter & Bratkartoffeln',
  } 
], fischgerichte: [
  {
    title: '2 Matjesfilets',
    price: '14,90€',
    description: 'an hausgemachter Remoulade & Bratkartoffeln'
  },
  {
    title: 'Brathering (süß-sauer)',
    price: '14,90€',
    description: 'mit roten Zwiebelringen und Bratkartoffeln',
  },
  {
    title: 'Ostseescholle',
    price: '19,90€',
    description: 'gebraten mit Gurkensalat & Bratkartoffeln',
  },
  // {
  //   title: 'Dorschfilet',
  //   price: '17,90€',
  //   description: 'gebraten, auf Rahmlinsen & Salzkartoffeln',
  // },
  {
    title: 'Zanderfilet',
    price: '19,90€',
    description: 'gebraten mit Kräuterbutter, Bratkartoffeln & Salat',
  },
  {
    title: 'Garnelen',
    price: '19,90€',
    description: 'gebraten, an Tomatenconcassé & Basilikum-Pesto-Spaghetti',
  }
]
// , wild: [
//   {
//     title: 'Geschmorte Wildkeule',
//     price: '19,50€',
//     description: 'an Preiselbeer-Rahm-Sauerkraut mit Petersilienkartoffeln',
//   },
//   {
//     title: 'Wildplatte Boddenrestaurant',
//     price: '21,50€',
//     description: 'Keule, Bratwurst & Boulette vom Wild an Wild Jus, Herzoginkartoffeln & Salat',
//   }
// ]
, kindergerichte: [
  {
    title: 'Kartoffelpuffer',
    price: '4,90€',
    description: 'mit Apfelmus',
  },
  {
    title: '5 Nuggets',
    price: '5,80€',
    description: 'mit Pommes frites',
  },
  {
    title: '4 Fischstäbchen',
    price: '5,80€',
    description: 'mit Pommes frites',
  },
  {
    title: 'Milchreis',
    price: '4,90€',
    description: 'mit Apfelmus & Zimt & Zucker',
  },
  {
    title: 'Pommes frites',
    price: '2,90€',
    description: '',  
  }
], vegetarisch: [
  {
    title: 'Ofenkartoffel',
    price: '8,50€',
    description: 'mit Kräuterquark',
  },
  {
    title: 'Käse-Sellerie-Schnitzel',
    price: '15,80€',
    description: 'an Basilikum-Pesto-Spaghetti & Tomatenconcassé',
  }
], saisonale_gerichte: [
  {
    title: 'Tomatensuppe',
    price: '6,50€',
    description: '',
  },
  {
    title: 'Spargelsuppe mit frischen Baguette',
    price: '6,50€',
    description: '',
  },
  {
    title: 'Schnitzel vom Schwein',
    price: '19,50€',
    description: 'mit Spargel, Sauce Hollandaise, Salzkartoffeln und Salatbeilage',
  },
  {
    title: 'Zanderfilet',
    price: '19,50€',
    description: 'gebraten mit Spargel, Sauce Hollandaise, Salzkartoffeln und Salatbeilage',
  }
], dessert: [
  {
    title: 'Apfelstrudel',
    price: '6,90€',
    description: 'mit Vanilleeis & Schlagsahne',
  },
  {
    title: 'Schokoladensouffle',
    price: '6,90€',
    description: 'mit Vanilleeis & Schlagsahne',
  }
]
  // {
  //   title: 'Spargelsuppe',
  //   price: '6,50€',
  //   description: 'mit Baguette'
  // },
  // {
  //   title: 'Schnitzel an Spargel',
  //   price: '17,90€',
  //   description: 'mit Salzkartoffeln & Hollondaise'
  // },
  // {
  //   title: 'Zander an Spargel',
  //   price: '19,90€',
  //   description: 'mit Salzkartoffeln & Hollondaise'
  // },
  // {
  //   title: 'Kürbissuppe',
  //   price: '6,50€',
  //   description: 'mit gerösteten Kürbiskernen & Baguette'
  // },
  // {
  //   title: 'Gratinierter Ofenkürbis',
  //   price: '13,50€',
  //   description: 'mit Waldpilzen & gemischtem Salat'
  // },
  // {
  //   title: 'Gebratenes Rumpsteak',
  //   price: '23,50€',
  //   description: 'unter einer Kürbis-Chilikruste an Rosmarinjus & Herzoginkartoffeln'
  // },
  // {
  //   title: 'Wildgulasch',
  //   price: '18,50€',
  //   description: 'mit Klößen oder Salzkartoffeln an Apfelrotkohl'
  // },
  // {
  //   title: 'Wildschnitzel',
  //   price: '18,50€',
  //   description: 'in Cornflakes-Panade, Preiselbeer Jus und Bratkartoffeln'
  // },
  // {
  //   title: 'Gebratene Wildleber „Berliner Art“',
  //   price: '16,80€',
  //   description: '(Apfel-Zwiebel-Gemüse) mit Kartoffelstampf'
  // },

// tägliche_spezialgerichte: [
//   {
//     title: 'Bouletten',
//     price: '7,50€',
//     description: 'mit Mischgemüse oder Rosenkohl & Salzkartoffeln',
//   },
//   {
//     title: 'Sülze',
//     price: '7,50€',
//     description: 'mit Bratkartoffeln & hausgemachter Remoulade',
//   },
//   {
//     title: 'Kassler',
//     price: '7,50€',
//     description: 'mit Sauerkraut und Salzkartoffeln',
//   },
//   {
//     title: 'Schweinebraten',
//     price: '7,50€',
//     description: 'mit Rotkohl und Salzkartoffeln',
//   },
//   {
//     title: 'Königsberger Klopse',
//     price: '7,50€',
//     description: 'mit Salzkartoffeln',
//   },
//   {
//     title: 'Verschiedene Eintöpfe',
//     price: '7,50€',
//     description: '',
//   },
//   {
//     title: 'Jägerschnitzel',
//     price: '7,50€',
//     description: 'mit Mischgemüse und Salzkartoffeln',
//   },
//   {
//     title: 'Grützwurst',
//     price: '7,50€',
//     description: 'mit Sauerkraut und Salzkartoffeln',
//   },
//   {
//     title: 'Senfei',
//     price: '7,50€',
//     description: 'mit Petersilienkartoffeln',
//   },
//   {
//     title: 'Paprikaschote',
//     price: '7,50€',
//     description: 'mit Salzkartoffeln',
//   },
// ]
}

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

const about_us = [
  {
    text: "Erleben Sie Gastfreundschaft in ihrer schönsten Form. Unser Restaurant vereint traditionelle deutsche Küche mit regionalen Spezialitäten und gut bürgerlichem Charme. Bei uns erwartet Sie eine vielfältige Auswahl an Tagesgerichten, sorgfältig zubereitet von unseren erfahrenen Köchen, um Ihnen stets frische und saisonale Gaumenfreuden zu bieten. Entdecken Sie darüber hinaus unsere einladende Bowlingbahn und Dartbereich – perfekt, um einen unterhaltsamen Abend mit Freunden zu verbringen. Unser Campertreff Boddenrestaurant eignet sich nicht nur für entspannte Stunden zu zweit, sondern auch für Familienfeiern und gesellige Treffen mit Ihren Liebsten. Hätten Sie ihr Essen gerne geliefert? Nutzen Sie unser Buffet für zu Hause und lassen Sie sich von unserer Küche versorgen. Und wenn Sie eine besondere Veranstaltung planen, stehen wir Ihnen gerne mit unserem Catering-Service zur Seite, um Ihre Feier zu einem unvergesslichen Ereignis zu machen. Wir freuen uns darauf, Sie im Campertreff Boddenrestaurant begrüßen zu dürfen."
  }
];


export default { menu, awards, about_us };
