import React from "react";
import './Menu.css'
import { Navbar } from "./components";
import { Header } from "./container";
import TopImage from "./container/TopImage/TopImage";
import GeneralMenu from "./container/Menu/GeneralMenu";
import { data, images } from './constants';
import Footer from "./container/Footer/Footer";

const Menu = () => {
    window.scrollTo(0, 0);
    let componentIndex = 0;
    function formatObjectName(name) {
        let words = name.replace(/_/g, ' ').split(' ');
        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return words.join(' ');
    }

    function categorizeObjects(arr) {
        let singles = {};
        let doubles = {};
        for (let category in arr) {
            if (arr[category].length > 5) {
                singles[category] = arr[category];
            } else {
                doubles[category] = arr[category];
                }
            }
        return {singles, doubles};
    }

    function pairProperties(obj) {
        let keys = Object.keys(obj);
        let result = [];
    
        for (let i = 0; i < keys.length; i += 2) {
            let pair = {};
            pair[keys[i]] = obj[keys[i]];
    
            if (keys[i + 1]) {
                pair[keys[i + 1]] = obj[keys[i + 1]];
            }
    
            result.push(pair);
        }
    
        return result;
    }

    let {singles, doubles} = categorizeObjects(data.menu);

    const renderSingles = Object.keys(singles).map((key, index) => {
        componentIndex++;
        return <GeneralMenu singleOrDouble="single" sectionNumber={componentIndex} title={formatObjectName(key)} items={singles[key]} image={images.galloway}/>
    });
    const renderDoubles = pairProperties(doubles).map((pair, index) => {
        componentIndex++;
        return <GeneralMenu singleOrDouble="double" sectionNumber={componentIndex} title={""} items={pair} image={images.galloway}/>
    });

    // for (let key of Object.keys(singles)) {
    //     console.log(formatObjectName(key));
    // }

    return (
        <div>
            <Navbar/>
            <TopImage/>
            <Header title={"Unsere Karte"}/>
            {renderSingles}
            {renderDoubles}
            <Footer/>
        </div>
    )
}
export default Menu;