import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';
import { data } from '../../constants';


const AboutUs = () => (
  <div>
    <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="flex__center" id="about">
      <h1 className="headtext__cormorant">Über uns</h1>
    </div>
      <div className="app__aboutus-overlay flex__center">
        {/* <img src={images.G} alt="G_overlay" /> */}
      </div>

      <div className="app__aboutus-content flex__center">
        <div className="app__aboutus-content_about">
          <p className="p__opensans">{data.about_us[0].text}</p>
          {/* <button type="button" className="custom__button">Know More</button> */}
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
