import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/dummy_meal.jpeg';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import sunset from '../assets/sunset.png';
import boddenrestaurant from '../assets/Boddenrestaurant.png';
import galloway from '../assets/galloway.jpg';
import steakmeal from '../assets/steak-meal.jpg';
import coke from '../assets/coke.jpg';
import coke2 from '../assets/coke2.jpg';
import steak2 from '../assets/steak2.jpg';
import steak3 from '../assets/steak3.jpg';
import steak4 from '../assets/steak4.jpg';
import soup from '../assets/soup.jpg';
import salad from '../assets/salad.jpg';
import schnitzel from '../assets/schnitzel.jpg';
import beer from '../assets/beer.jpg';
import fish from '../assets/fish.jpg';
import desert from '../assets/desert.jpg';
import pommes from '../assets/pommes.jpg';
import kicker from '../assets/kicker.jpg';
import bäume from '../assets/bäume.jpg';
import kegeln from '../assets/kegeln.jpg';
import bäume2 from '../assets/bäume2.jpg';
import image1 from '../assets/1.JPG';
import image2 from '../assets/2.JPG';
import image3 from '../assets/3.JPG';
import image4 from '../assets/4.JPG';
import image5 from '../assets/5.JPG';
import image6 from '../assets/6.JPG';
import image7 from '../assets/7.JPG';
import image8 from '../assets/8.JPG';
import image9 from '../assets/9.JPG';
import image10 from '../assets/10.JPG';
import image11 from '../assets/11.JPG';
import image12 from '../assets/12.JPG';
import image13 from '../assets/13.JPG';
import image14 from '../assets/14.JPG';
import image15 from '../assets/15.JPG';
import image16 from '../assets/16.JPG';
import image17 from '../assets/17.JPG';
import image18 from '../assets/18.JPG';
import image19 from '../assets/19.JPG';
import image20 from '../assets/20.JPG';
import image21 from '../assets/21.JPG';
import image22 from '../assets/22.JPG';
import image23 from '../assets/23.JPG';
import image24 from '../assets/24.JPG';
import image25 from '../assets/25.JPG';
import image26 from '../assets/26.JPG';
import image27 from '../assets/27.JPG';
import image28 from '../assets/28.JPG';
import image29 from '../assets/29.JPG';
import image30 from '../assets/30.JPG';
import image31 from '../assets/31.JPG';
import image32 from '../assets/32.JPG';
import image33 from '../assets/33.JPG';
import image34 from '../assets/34.JPG';
import image35 from '../assets/35.JPG';
import image36 from '../assets/36.JPG';
import image37 from '../assets/37.JPG';
import image38 from '../assets/38.JPG';
import image39 from '../assets/39.JPG';
import image40 from '../assets/40.JPG';
import image41 from '../assets/41.JPG';
import image42 from '../assets/42.JPG';
import image43 from '../assets/43.JPG';
import image44 from '../assets/44.JPG';
import image45 from '../assets/45.JPG';
import image46 from '../assets/46.JPG';
import image47 from '../assets/47.JPG';
import image48 from '../assets/48.JPG';
import image49 from '../assets/49.JPG'; import image50 from '../assets/50.JPG'; import image51 from '../assets/51.JPG'; import image52 from '../assets/52.JPG'; import image53 from '../assets/53.JPG'; import image54 from '../assets/54.JPG'; import image55 from '../assets/55.JPG'; import image56 from '../assets/56.JPG'; import image57 from '../assets/57.JPG'; import image58 from '../assets/58.JPG'; import image59 from '../assets/59.JPG'; import image60 from '../assets/60.JPG'; 
import skat from '../assets/skat.jpg';
import openingHoursFall from '../assets/opening-hours-fall.jpg'

export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  sunset,
  boddenrestaurant,
  galloway,
  steakmeal,
  coke, coke2,
  steak2,
  steak3,
  steak4,
  soup,
  salad,
  schnitzel,
  beer,
  fish,
  desert,
  pommes,
  kicker,
  bäume,
  kegeln,
  bäume2,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33, image34, image35, image36, image37, image38, image39, image40, image41, image42, image43, image44, image45, image46, image47, image48, image49, image50, image51, image52, image53, image54, image55, image56, image57, image58, image59, image60,
  skat,
  openingHoursFall
};
