import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Home from './Home';
import Menu from './Menu';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />} />
                <Route path="karte" element={<Menu />} />
                <Route path="about" element={<Home/>}/>
                <Route path="contact" element={<Home/>}/>
                <Route path="book" element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />)
